import React, { Component } from "react";
// import { View, Text } from "react-native";
// import {
//   greenColor,
//   redColor,
//   accentText,
//   blackColor,
// } from "../../assets/colors";
import ModalManager from "./ModalManager";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "../Button";

export default class Modal extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
      style: {},
      message: null,
      color: "red",
      // duration: 3000,
      loading: false,
    };
    this.showMessage = this.showMessage.bind(this);
    this.hideMessage = this.hideMessage.bind(this);
    this.showCalled = true;
    if (!this._id) this._id = srid();
  }

  componentDidMount() {
    ModalManager.register(this);
  }
  componentWillUnmount() {
    ModalManager.unregister(this);
  }

  showMessage(config) {
    this.showCalled = true;
    console.log("setState");
    this.setState({ open: true, ...config });
  }

  hideMessage() {
    this.setState({
      open: false,
      message: null,
      color: "red",
      // duration: 3000,
    });
  }

  render() {
    let {
      open,
      title,
      text,
      onSubmit,
      loading,
      style,
      message,
      type,
      color,
      duration,
      className,
    } = this.state;

    if (duration && typeof duration == "number" && this.showCalled) {
      setTimeout(() => {
        this.showCalled = false;
        this.hideMessage();
      }, duration);
    }

    let backgroundColor = null;
    switch (type) {
      case "danger":
        backgroundColor = "red";
        break;
      case "info":
        backgroundColor = "red";
        break;
      case "success":
        backgroundColor = "red";
        break;
    }

    return (
      // <div
      //   style={{ ...styles.container, ...style, backgroundColor }}
      //   className={className}
      // >
      //   <Text style={[styles.text, { color: color || accentText }]}>
      //   </Text>
      //   {message}
      // </div>
      <Dialog
        open={open}
        onClose={() => this.setState({ open: false })}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle textAlign="center">{title}</DialogTitle>
        {text ?? (
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {text}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            variant="outlined"
            text="Cancel"
            onClick={() => this.setState({ open: false })}
          />
          <Button
            text="Yes"
            onClick={() =>
              onSubmit((loading) => this.setState({ ...this.state, loading }))
            }
            loading={loading}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export function showMessage(...args) {
  const ref = ModalManager.getDefault();
  if (!!ref) {
    ref.showMessage(...args);
  }
}

export function hideMessage(...args) {
  const ref = ModalManager.getDefault();
  if (!!ref) {
    ref.hideMessage();
  }
}

function srid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return `${s4()}-${s4()}-${s4()}`;
}

const styles = {
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    zIndex: 1000,
  },
  text: {
    fontFamily: "IRANYekanMobileFaNum",
    fontStyle: "normal",
    fontSize: 14,
    textAlign: "right",
    writingDirection: "rtl",
    lineHeight: 25,
    paddingHorizontal: 20,
  },
};
