import {
  GET_CARDS_LIST_STARTED,
  GET_CARDS_LIST_SUCCESS,
  GET_CARDS_LIST_FAILURE,
  CARD_PIN_RESET_STARTED,
  CARD_PIN_RESET_SUCCESS,
  CARD_PIN_RESET_FAILURE,
  CARD_PIN_CHANGE_STARTED,
  CARD_PIN_CHANGE_SUCCESS,
  CARD_PIN_CHANGE_FAILURE,
  GET_CARD_TRANSACTIONS_STARTED,
  GET_CARD_TRANSACTIONS_SUCCESS,
  GET_CARD_TRANSACTIONS_FAILURE,
  ACTIVATE_PHYSICAL_CARD_STARTED,
  ACTIVATE_PHYSICAL_CARD_SUCCESS,
  ACTIVATE_PHYSICAL_CARD_FAILURE,
  ACTIVATE_PHYSICAL_CARD_DEFAULT,
  TOP_UP_CARD_STARTED,
  TOP_UP_CARD_SUCCESS,
  TOP_UP_CARD_FAILURE,
  UPDATE_CARD_STATUS_STARTED,
  UPDATE_CARD_STATUS_SUCCESS,
  UPDATE_CARD_STATUS_FAILURE,
  CREATE_VIRTUAL_CARD_SUCCESS,
  CREATE_VIRTUAL_CARD_FAILURE,
  CREATE_VIRTUAL_CARD_STARTED,
  SET_CARD_LIMITATION_STARTED,
  SET_CARD_LIMITATION_SUCCESS,
  SET_CARD_LIMITATION_FAILURE,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: ACTIVATE_PHYSICAL_CARD_DEFAULT,
  cards: [],
  balances: [],
  cardTransactions: [],
  error: "",
};
export const CardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_PHYSICAL_CARD_STARTED:
    case GET_CARDS_LIST_STARTED:
    case CARD_PIN_RESET_STARTED:
    case CARD_PIN_CHANGE_STARTED:
    case GET_CARD_TRANSACTIONS_STARTED:
    case TOP_UP_CARD_STARTED:
    case CREATE_VIRTUAL_CARD_STARTED:
    case UPDATE_CARD_STATUS_STARTED:
    case SET_CARD_LIMITATION_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case ACTIVATE_PHYSICAL_CARD_SUCCESS:
    case CARD_PIN_RESET_SUCCESS:
    case CARD_PIN_CHANGE_SUCCESS:
    case TOP_UP_CARD_SUCCESS:
    case CREATE_VIRTUAL_CARD_SUCCESS:
    case UPDATE_CARD_STATUS_SUCCESS:
    case SET_CARD_LIMITATION_SUCCESS:
      return {
        ...state,
        rType: action.rType,
        error: "",
      };
    case GET_CARDS_LIST_SUCCESS:
      return {
        ...state,
        rType: action.rType,
        cards: action.payload.cards,
        balances: action.payload.balances,
        error: "",
      };
    case GET_CARD_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        rType: action.rType,
        cardTransactions: action.payload.cardTransactions,
        error: "",
      };
    case ACTIVATE_PHYSICAL_CARD_FAILURE:
    case GET_CARDS_LIST_FAILURE:
    case CARD_PIN_RESET_FAILURE:
    case CARD_PIN_CHANGE_FAILURE:
    case GET_CARD_TRANSACTIONS_FAILURE:
    case TOP_UP_CARD_FAILURE:
    case CREATE_VIRTUAL_CARD_FAILURE:
    case UPDATE_CARD_STATUS_FAILURE:
    case SET_CARD_LIMITATION_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};
