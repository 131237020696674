import { createTheme } from "@mui/material/styles";
import {
  apricotOrangeColor,
  backgroundColor,
  blackColor,
  charcoalGrayColor,
  cloudBlueColor,
  crimsonRedColor,
  errorColor,
  firebrickRedColor,
  forestGreenColor,
  graphiteGrayColor,
  jungleGreenColor,
  midnightGrayColor,
  peachySalmonColor,
  primaryColor,
  quaternaryColor,
  raspberryPinkColor,
  rosewaterPinkColor,
  sereneSkyBlueColor,
  silverCloudColor,
  softIvoryColor,
  softMoonbeamColor,
  successColor,
  warningColor,
  whiteColor,
} from "../../assets/colors";
// --------------------------------------------------------------------------------
// NOTE Create Custom Theme
export const theme = createTheme({
  // direction: "rtl",
  palette: {
    // mode: "dark",
    // background: {
    //   paper: backgroundColor,
    //   default: backgroundColor,
    // },
    // background: {
    //   default: "#FFFFFF",
    // },
    primary: {
      main: primaryColor,
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#ffffff",
    },
    tertiary: {
      main: sereneSkyBlueColor,
      contrastText: primaryColor,
    },
    quaternary: {
      main: quaternaryColor,
      contrastText: graphiteGrayColor,
    },
    graphiteGray: {
      main: graphiteGrayColor,
      contrastText: whiteColor,
    },
    charcoalGray: {
      main: charcoalGrayColor,
      contrastText: whiteColor,
    },
    error: {
      main: errorColor,
    },
    success: {
      main: successColor,
    },
    warning: {
      main: warningColor,
    },
    black: {
      main: blackColor,
    },
    sereneSkyBlue: {
      main: sereneSkyBlueColor,
      contrastText: graphiteGrayColor,
    },
    raspberryPink: {
      main: raspberryPinkColor,
      contrastText: whiteColor,
    },
    peachySalmon: {
      main: peachySalmonColor,
      contrastText: whiteColor,
    },
    softIvory: {
      main: softIvoryColor,
      contrastText: graphiteGrayColor,
    },
    cloudBlue: {
      main: cloudBlueColor,
      contrastText: graphiteGrayColor,
    },
    crimsonRed: {
      main: crimsonRedColor,
      contrastText: whiteColor,
    },
    rosewaterPink: {
      main: rosewaterPinkColor,
      contrastText: crimsonRedColor,
    },
    silverCloud: {
      main: silverCloudColor,
      contrastText: graphiteGrayColor,
    },
    apricotOrange: {
      main: apricotOrangeColor,
    },
    jungleGreen: {
      main: jungleGreenColor,
    },
    firebrickRed: {
      main: firebrickRedColor,
    },
    forestGreen: {
      main: forestGreenColor,
    },
    midnightGray: {
      main: midnightGrayColor,
      contrastText: whiteColor,
    },
    softMoonbeam: {
      main: softMoonbeamColor,
      contrastText: whiteColor,
    },
    disabled: {
      main: "rgba(0, 0, 0, 0.12)",
      contrastText: "rgba(0, 0, 0, 0.26)",
    },
  },
  typography: {
    fontFamily: ["DM Sans", "Noto Sans"].join(","),
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: 14,
          paddingTop: 12.5,
          paddingBottom: 12.5,
        },
        adornedEnd: {
          paddingRight: 6,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          height: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          height: ".867em",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          paddingTop: ".6em",
          paddingBottom: ".6em",
          // color: "#ffffff",
        },
        text: {
          ":hover": {
            backgroundColor: "transparent",
          },
        },
        sizeSmall: {
          fontSize: 14,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#F2F3F7",
          borderRadius: 15,
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: graphiteGrayColor,
          whiteSpace: "pre-line",
        },
      },
      // defaultProps: { color: graphiteGrayColor },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: graphiteGrayColor,
        },
      },
    },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       background: "none",
    //     },
    //   },
    // },
  },
});
