export const backgroundColor = "#1D222B";
export const primaryColor = "#41adce";
export const sidebarColor = "#0B111D";
export const borderColor = "#515763";
export const paperColor = "#272F3A";
export const inputColor = "#F2F3F7";
export const textColor = "#5E6D7F";
export const errorColor = "#ED2454";
export const successColor = "#7bed9f";
export const warningColor = "#ffc107";
export const grayColor = "#505354";

export const darkGrayColor = "#1A1C1E";
export const visaColor = "#2771FF";
export const troyColor = "#00C3FF";
export const quaternaryColor = "#E4E6EF";
export const dividerColor = "#E9EBF2";
// --------------------------------------------------------------------------------
export const lightOpalColor = "rgba(255, 255, 255, 0.20)";
export const softIvoryColor = "rgba(255, 255, 255, 0.91)";

export const secondaryColor = "#2892ff";
export const blackColor = "#000000";
export const charcoalGrayColor = "#1A1C1E";
export const graphiteGrayColor = "#33383D";
export const deepSteelBlueColor = "#33383D99";
export const slateGrayColor = "#8C8D95";
export const steelGrayColor = "#999B9D";
export const silverMistColor = "#D7DAE6";
export const silverCloudColor = "#F2F3F7";
export const whiteColor = "#ffffff";
export const winterSkyBlueColor = "#E9EBF2";
export const skyMistBlueColor = "#E4F2FF";
export const sereneSkyBlueColor = "#D7E9EF";
export const celestialBlueColor = "#E2F3FF";
export const azureBlueColor = "#52A8FF";
export const cloudBlueColor = "#E7E9EF";
export const slateBlueColor = "#778593";
export const royalBlueColor = "#1920D3";
export const electricBlueColor = "#0B53E9";
export const ceruleanBlueColor = "#2868CA";
export const skyBlueColor = "#5498FF";
export const periwinkleBlueColor = "#C9DAFF";
export const dodgerBlueColor = "#32C2FF";
export const raspberryRedColor = "#E81A4B";
export const coralRedColor = "#ED4C5C";
export const raspberryPinkColor = "#F93161";
export const crimsonRedColor = "#DE1E4C";
export const blushPinkColor = "#FFC7D1";
export const rosewaterPinkColor = "#EFD7DE";
export const peachySalmonColor = "#FF9675";
export const peachBlushColor = "#FFEADE";
export const coralOrangeColor = "#FF8E6A";
export const paleRoseColor = "#FFE9E2";
export const forestGreenColor = "#2DA56B";
export const mintGreenColor = "#0BC36B";
export const turquoiseGreenColor = "#3BE2A6";
export const emeraldGreenColor = "#1ECC8D";
export const terraCottaOrangeColor = "#EA8930";
export const linenWhiteColor = "#FFF5EB";
export const mistyBlueColor = "#A5B8CB";
export const silverBlueColor = "#DCDDE2";
export const silverMistBlueColor = "#DDDFE7";
export const periwinkleGrayColor = "#E3E6F0";
export const steelBlueGrayColor = "#858D96";
export const powderBlueColor = "#E7EAF4";
export const lightSteelBlueColor = "#91A0AF";
export const lightGrayColor = "#D4D4D4";
export const mediumGrayColor = "#A0A0A0";
export const frostyBlueColor = "#E5E8F1";
export const silverSageColor = "#BDC1D1";
export const mistyLavenderColor = "#AFB3C2";
export const stormySlateBlueColor = "#59626B";
export const royalAzureBlueColor = "#3267D9";
export const silverGrayColor = "#C7C8CF";
export const jungleGreenColor = "#36C565";
export const apricotOrangeColor = "#F99F4A";
export const firebrickRedColor = "#E44242";
export const mintFrostColor = "#D6FFDA";
export const blushRoseColor = "#FFE4E8 ";
export const rubyRedColor = "#E84646";
export const gentlePeriwinkleColor = "#DFE2EF";
export const whisperingLilacColor = "#DCE0EE";
export const midnightGrayColor = "#00000026";
export const softMoonbeamColor = "#FFFFFF14";
export const translucentMistColor = "#FFFFFF26";
export const mintBreezeColor = "#B8F8D1";
export const deepTealColor = "#004434";
export const forestTealColor = "#22AD5C";
export const blushCoralColor = "#FED1D1";
export const fieryRedColor = "#E10E0E";
// --------------------------------------------------------------------------------
export const softMistGradient =
  "linear-gradient(179deg, #ffffff7d -5.74%, #ffffffe6 7.37%, #fff 99.14%)";
export const desertMirageGradient =
  "linear-gradient(180deg, rgba(255, 255, 255, 0.49) 0.28%, #FFF 38.48%, #FFF 99.72%)";
export const whiteFadeGradient =
  "linear-gradient(90deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%)";
