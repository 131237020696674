import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "../locales/en.json";
import ruTranslations from "../locales/ru.json";
import trTranslations from "../locales/tr.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: { translation: enTranslations },
  ru: { translation: ruTranslations },
  tr: { translation: trTranslations },
};

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: DETECTION_OPTIONS,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
