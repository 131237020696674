export const SIGNUP_STARTED = "SIGNUP_STARTED";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const SIGNIN_DEFAULT = "SIGNIN_DEFAULT";
export const SIGNIN_STARTED = "SIGNIN_STARTED";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";

export const VERIFY_STARTED = "VERIFY_STARTED";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAILURE = "VERIFY_FAILURE";

export const LOGOUT_STARTED = "LOGOUT_STARTED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const FORGOT_PASSWORD_STEP_ONE_STARTED =
  "FORGOT_PASSWORD_STEP_ONE_STARTED";
export const FORGOT_PASSWORD_STEP_ONE_SUCCESS =
  "FORGOT_PASSWORD_STEP_ONE_SUCCESS";
export const FORGOT_PASSWORD_STEP_ONE_FAILURE =
  "FORGOT_PASSWORD_STEP_ONE_FAILURE";

export const FORGOT_PASSWORD_STEP_TWO_STARTED =
  "FORGOT_PASSWORD_STEP_TWO_STARTED";
export const FORGOT_PASSWORD_STEP_TWO_SUCCESS =
  "FORGOT_PASSWORD_STEP_TWO_SUCCESS";
export const FORGOT_PASSWORD_STEP_TWO_FAILURE =
  "FORGOT_PASSWORD_STEP_TWO_FAILURE";

export const FORGOT_PASSWORD_STEP_THREE_STARTED =
  "FORGOT_PASSWORD_STEP_THREE_STARTED";
export const FORGOT_PASSWORD_STEP_THREE_SUCCESS =
  "FORGOT_PASSWORD_STEP_THREE_SUCCESS";
export const FORGOT_PASSWORD_STEP_THREE_FAILURE =
  "FORGOT_PASSWORD_STEP_THREE_FAILURE";

export const CREATE_PERSONAL_INFORMATION_STARTED =
  "CREATE_PERSONAL_INFORMATION_STARTED";
export const CREATE_PERSONAL_INFORMATION_SUCCESS =
  "CREATE_PERSONAL_INFORMATION_SUCCESS";
export const CREATE_PERSONAL_INFORMATION_FAILURE =
  "CREATE_PERSONAL_INFORMATION_FAILURE";

export const GET_NATIONALITIES_STARTED = "GET_NATIONALITIES_STARTED";
export const GET_NATIONALITIES_SUCCESS = "GET_NATIONALITIES_SUCCESS";
export const GET_NATIONALITIES_FAILURE = "GET_NATIONALITIES_FAILURE";

export const CREATE_NATIONALITY_INFORMATION_STARTED =
  "CREATE_NATIONALITY_INFORMATION_STARTED";
export const CREATE_NATIONALITY_INFORMATION_SUCCESS =
  "CREATE_NATIONALITY_INFORMATION_SUCCESS";
export const CREATE_NATIONALITY_INFORMATION_FAILURE =
  "CREATE_NATIONALITY_INFORMATION_FAILURE";

export const CREATE_ADDRESS_INFORMATION_STARTED =
  "CREATE_ADDRESS_INFORMATION_STARTED";
export const CREATE_ADDRESS_INFORMATION_SUCCESS =
  "CREATE_ADDRESS_INFORMATION_SUCCESS";
export const CREATE_ADDRESS_INFORMATION_FAILURE =
  "CREATE_ADDRESS_INFORMATION_FAILURE";

export const CREATE_DOCUMENT_INFORMATION_STARTED =
  "CREATE_DOCUMENT_INFORMATION_STARTED";
export const CREATE_DOCUMENT_INFORMATION_SUCCESS =
  "CREATE_DOCUMENT_INFORMATION_SUCCESS";
export const CREATE_DOCUMENT_INFORMATION_FAILURE =
  "CREATE_DOCUMENT_INFORMATION_FAILURE";

export const VERIFY_KYC_REVIEW_STARTED = "VERIFY_KYC_REVIEW_STARTED";
export const VERIFY_KYC_REVIEW_SUCCESS = "VERIFY_KYC_REVIEW_SUCCESS";
export const VERIFY_KYC_REVIEW_FAILURE = "VERIFY_KYC_REVIEW_FAILURE";

export const CHECK_EMAIL_STARTED = "CHECK_EMAIL_STARTED";
export const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS";
export const CHECK_EMAIL_FAILURE = "CHECK_EMAIL_FAILURE";

export const GET_PROFILE_STARTED = "GET_PROFILE_STARTED";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const VERIFY_EMAIL_STARTED = "VERIFY_EMAIL_STARTED";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const VERIFY_EMAIL_TOKEN_STARTED = "VERIFY_EMAIL_TOKEN_STARTED";
export const VERIFY_EMAIL_TOKEN_SUCCESS = "VERIFY_EMAIL_TOKEN_SUCCESS";
export const VERIFY_EMAIL_TOKEN_FAILURE = "VERIFY_EMAIL_TOKEN_FAILURE";

export const VERIFY_MOBILE_STARTED = "VERIFY_MOBILE_STARTED";
export const VERIFY_MOBILE_SUCCESS = "VERIFY_MOBILE_SUCCESS";
export const VERIFY_MOBILE_FAILURE = "VERIFY_MOBILE_FAILURE";

export const VERIFY_MOBILE_TOKEN_STARTED = "VERIFY_MOBILE_TOKEN_STARTED";
export const VERIFY_MOBILE_TOKEN_SUCCESS = "VERIFY_MOBILE_TOKEN_SUCCESS";
export const VERIFY_MOBILE_TOKEN_FAILURE = "VERIFY_MOBILE_TOKEN_FAILURE";

export const CHANGE_KYC_STATUS_STARTED = "CHANGE_KYC_STATUS_STARTED";
export const CHANGE_KYC_STATUS_SUCCESS = "CHANGE_KYC_STATUS_SUCCESS";
export const CHANGE_KYC_STATUS_FAILURE = "CHANGE_KYC_STATUS_FAILURE";

export const UPLOAD_FILE_STARTED = "UPLOAD_FILE_STARTED";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const DOWNLOAD_FILE_STARTED = "DOWNLOAD_FILE_STARTED";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAILURE = "DOWNLOAD_FILE_FAILURE";

export const DELETE_UPLOADED_FILE_STARTED = "DELETE_UPLOADED_FILE_STARTED";
export const DELETE_UPLOADED_FILE_SUCCESS = "DELETE_UPLOADED_FILE_SUCCESS";
export const DELETE_UPLOADED_FILE_FAILURE = "DELETE_UPLOADED_FILE_FAILURE";

export const DOWNLOAD_AGREEMENT_STARTED = "DOWNLOAD_AGREEMENT_STARTED";
export const DOWNLOAD_AGREEMENT_SUCCESS = "DOWNLOAD_AGREEMENT_SUCCESS";
export const DOWNLOAD_AGREEMENT_FAILURE = "DOWNLOAD_AGREEMENT_FAILURE";

export const GET_COUNTRIES_STARTED = "GET_COUNTRIES_STARTED";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_STATES_STARTED = "GET_STATES_STARTED";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAILURE = "GET_STATES_FAILURE";

export const GET_CITIES_STARTED = "GET_CITIES_STARTED";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAILURE = "GET_CITIES_FAILURE";

export const GET_OCCUPATIONS_STARTED = "GET_OCCUPATIONS_STARTED";
export const GET_OCCUPATIONS_SUCCESS = "GET_OCCUPATIONS_SUCCESS";
export const GET_OCCUPATIONS_FAILURE = "GET_OCCUPATIONS_FAILURE";

export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";

export const RESET_ERROR_SUCCESS = "RESET_ERROR_SUCCESS";

export const GET_KNOW_YOUR_CUSTOMER_STARTED = "GET_KNOW_YOUR_CUSTOMER_STARTED";
export const GET_KNOW_YOUR_CUSTOMER_SUCCESS = "GET_KNOW_YOUR_CUSTOMER_SUCCESS";
export const GET_KNOW_YOUR_CUSTOMER_FAILURE = "GET_KNOW_YOUR_CUSTOMER_FAILURE";

export const GET_CAPTCHA_STARTED = "GET_CAPTCHA_STARTED";
export const GET_CAPTCHA_SUCCESS = "GET_CAPTCHA_SUCCESS";
export const GET_CAPTCHA_FAILURE = "GET_CAPTCHA_FAILURE";

export const GET_AGREEMENTS_STARTED = "GET_AGREEMENTS_STARTED";
export const GET_AGREEMENTS_SUCCESS = "GET_AGREEMENTS_SUCCESS";
export const GET_AGREEMENTS_FAILURE = "GET_AGREEMENTS_FAILURE";

export const GET_WALLETS_STARTED = "GET_WALLETS_STARTED";
export const GET_WALLETS_SUCCESS = "GET_WALLETS_SUCCESS";
export const GET_WALLETS_FAILURE = "GET_WALLETS_FAILURE";

export const GET_INACTIVE_WALLETS_STARTED = "GET_INACTIVE_WALLETS_STARTED";
export const GET_INACTIVE_WALLETS_SUCCESS = "GET_INACTIVE_WALLETS_SUCCESS";
export const GET_INACTIVE_WALLETS_FAILURE = "GET_INACTIVE_WALLETS_FAILURE";

export const ADD_WALLET_STARTED = "ADD_WALLET_STARTED";
export const ADD_WALLET_SUCCESS = "ADD_WALLET_SUCCESS";
export const ADD_WALLET_FAILURE = "ADD_WALLET_FAILURE";

export const ACTIVATE_PHYSICAL_CARD_DEFAULT = "ACTIVATE_PHYSICAL_CARD_DEFAULT";
export const ACTIVATE_PHYSICAL_CARD_STARTED = "ACTIVATE_PHYSICAL_CARD_STARTED";
export const ACTIVATE_PHYSICAL_CARD_SUCCESS = "ACTIVATE_PHYSICAL_CARD_SUCCESS";
export const ACTIVATE_PHYSICAL_CARD_FAILURE = "ACTIVATE_PHYSICAL_CARD_FAILURE";

export const GET_CARDS_LIST_STARTED = "GET_CARDS_LIST_STARTED";
export const GET_CARDS_LIST_SUCCESS = "GET_CARDS_LIST_SUCCESS";
export const GET_CARDS_LIST_FAILURE = "GET_CARDS_LIST_FAILURE";

export const TOP_UP_CARD_STARTED = "TOP_UP_CARD_STARTED";
export const TOP_UP_CARD_SUCCESS = "TOP_UP_CARD_SUCCESS";
export const TOP_UP_CARD_FAILURE = "TOP_UP_CARD_FAILURE";

export const GET_CARD_LIMITATION_STARTED = "GET_CARD_LIMITATION_STARTED";
export const GET_CARD_LIMITATION_SUCCESS = "GET_CARD_LIMITATION_SUCCESS";
export const GET_CARD_LIMITATION_FAILURE = "GET_CARD_LIMITATION_FAILURE";

export const GET_CARD_LIMITATION_RATE_STARTED =
  "GET_CARD_LIMITATION_RATE_STARTED";
export const GET_CARD_LIMITATION_RATE_SUCCESS =
  "GET_CARD_LIMITATION_RATE_SUCCESS";
export const GET_CARD_LIMITATION_RATE_FAILURE =
  "GET_CARD_LIMITATION_RATE_FAILURE";

export const SET_CARD_LIMITATION_STARTED = "SET_CARD_LIMITATION_STARTED";
export const SET_CARD_LIMITATION_SUCCESS = "SET_CARD_LIMITATION_SUCCESS";
export const SET_CARD_LIMITATION_FAILURE = "SET_CARD_LIMITATION_FAILURE";

export const GET_CARD_TRANSACTIONS_STARTED = "GET_CARD_TRANSACTIONS_STARTED";
export const GET_CARD_TRANSACTIONS_SUCCESS = "GET_CARD_TRANSACTIONS_SUCCESS";
export const GET_CARD_TRANSACTIONS_FAILURE = "GET_CARD_TRANSACTIONS_FAILURE";

export const CARD_PIN_RESET_STARTED = "CARD_PIN_RESET_STARTED";
export const CARD_PIN_RESET_SUCCESS = "CARD_PIN_RESET_SUCCESS";
export const CARD_PIN_RESET_FAILURE = "CARD_PIN_RESET_FAILURE";

export const CARD_PIN_CHANGE_STARTED = "CARD_PIN_CHANGE_STARTED";
export const CARD_PIN_CHANGE_SUCCESS = "CARD_PIN_CHANGE_SUCCESS";
export const CARD_PIN_CHANGE_FAILURE = "CARD_PIN_CHANGE_FAILURE";

export const CREATE_VIRTUAL_CARD_STARTED = "CREATE_VIRTUAL_CARD_STARTED";
export const CREATE_VIRTUAL_CARD_SUCCESS = "CREATE_VIRTUAL_CARD_SUCCESS";
export const CREATE_VIRTUAL_CARD_FAILURE = "CREATE_VIRTUAL_CARD_FAILURE";

export const UPDATE_CARD_STATUS_STARTED = "UPDATE_CARD_STATUS_STARTED";
export const UPDATE_CARD_STATUS_SUCCESS = "UPDATE_CARD_STATUS_SUCCESS";
export const UPDATE_CARD_STATUS_FAILURE = "UPDATE_CARD_STATUS_FAILURE";

export const EDIT_CARD_NAME_STARTED = "EDIT_CARD_NAME_STARTED";
export const EDIT_CARD_NAME_SUCCESS = "EDIT_CARD_NAME_SUCCESS";
export const EDIT_CARD_NAME_FAILURE = "EDIT_CARD_NAME_FAILURE";

export const GET_EVENTS_STARTED = "GET_EVENTS_STARTED";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";

export const GET_EVENT_FEE_STARTED = "GET_EVENT_FEE_STARTED";
export const GET_EVENT_FEE_SUCCESS = "GET_EVENT_FEE_SUCCESS";
export const GET_EVENT_FEE_FAILURE = "GET_EVENT_FEE_FAILURE";

export const GET_TRANSACTION_LIST_STARTED = "GET_TRANSACTION_LIST_STARTED";
export const GET_TRANSACTION_LIST_SUCCESS = "GET_TRANSACTION_LIST_SUCCESS";
export const GET_TRANSACTION_LIST_FAILURE = "GET_TRANSACTION_LIST_FAILURE";

export const GET_LAST_TRANSACTIONS_STARTED = "GET_LAST_TRANSACTIONS_STARTED";
export const GET_LAST_TRANSACTIONS_SUCCESS = "GET_LAST_TRANSACTIONS_SUCCESS";
export const GET_LAST_TRANSACTIONS_FAILURE = "GET_LAST_TRANSACTIONS_FAILURE";

export const GET_LAST_EXCHANGES_STARTED = "GET_LAST_EXCHANGES_STARTED";
export const GET_LAST_EXCHANGES_SUCCESS = "GET_LAST_EXCHANGES_SUCCESS";
export const GET_LAST_EXCHANGES_FAILURE = "GET_LAST_EXCHANGES_FAILURE";

export const GET_LAST_TOP_UPS_STARTED = "GET_LAST_TOP_UPS_STARTED";
export const GET_LAST_TOP_UPS_SUCCESS = "GET_LAST_TOP_UPS_SUCCESS";
export const GET_LAST_TOP_UPS_FAILURE = "GET_LAST_TOP_UPS_FAILURE";

export const GET_USER_SETTING_STARTED = "GET_USER_SETTING_STARTED";
export const GET_USER_SETTING_SUCCESS = "GET_USER_SETTING_SUCCESS";
export const GET_USER_SETTING_FAILURE = "GET_USER_SETTING_FAILURE";

export const SEND_OTP_STARTED = "SEND_OTP_STARTED";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

export const VERIFY_OTP_STARTED = "VERIFY_OTP_STARTED";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const GET_TOP_UP_COUNTRIES_STARTED = "GET_TOP_UP_COUNTRIES_STARTED";
export const GET_TOP_UP_COUNTRIES_SUCCESS = "GET_TOP_UP_COUNTRIES_SUCCESS";
export const GET_TOP_UP_COUNTRIES_FAILURE = "GET_TOP_UP_COUNTRIES_FAILURE";

export const GET_TOP_UP_BANKS_STARTED = "GET_TOP_UP_BANKS_STARTED";
export const GET_TOP_UP_BANKS_SUCCESS = "GET_TOP_UP_BANKS_SUCCESS";
export const GET_TOP_UP_BANKS_FAILURE = "GET_TOP_UP_BANKS_FAILURE";

export const GET_TOP_UP_IBAN_STARTED = "GET_TOP_UP_IBAN_STARTED";
export const GET_TOP_UP_IBAN_SUCCESS = "GET_TOP_UP_IBAN_SUCCESS";
export const GET_TOP_UP_IBAN_FAILURE = "GET_TOP_UP_IBAN_FAILURE";

export const GET_WALLET_TOP_UP_TRANSACTIONS_STARTED =
  "GET_WALLET_TOP_UP_TRANSACTIONS_STARTED";
export const GET_WALLET_TOP_UP_TRANSACTIONS_SUCCESS =
  "GET_WALLET_TOP_UP_TRANSACTIONS_SUCCESS";
export const GET_WALLET_TOP_UP_TRANSACTIONS_FAILURE =
  "GET_WALLET_TOP_UP_TRANSACTIONS_FAILURE";

export const CREATE_EXCHANGE_STARTED = "CREATE_EXCHANGE_STARTED";
export const CREATE_EXCHANGE_SUCCESS = "CREATE_EXCHANGE_SUCCESS";
export const CREATE_EXCHANGE_FAILURE = "CREATE_EXCHANGE_FAILURE";

export const CONFIRM_EXCHANGE_STARTED = "CONFIRM_EXCHANGE_STARTED";
export const CONFIRM_EXCHANGE_SUCCESS = "CONFIRM_EXCHANGE_SUCCESS";
export const CONFIRM_EXCHANGE_FAILURE = "CONFIRM_EXCHANGE_FAILURE";

export const GET_TIME_ZONES_STARTED = "GET_TIME_ZONES_STARTED";
export const GET_TIME_ZONES_SUCCESS = "GET_TIME_ZONES_SUCCESS";
export const GET_TIME_ZONES_FAILURE = "GET_TIME_ZONES_FAILURE";

export const CHANGE_SETTING_STARTED = "CHANGE_SETTING_STARTED";
export const CHANGE_SETTING_SUCCESS = "CHANGE_SETTING_SUCCESS";
export const CHANGE_SETTING_FAILURE = "CHANGE_SETTING_FAILURE";

export const CHECK_CURRENT_PASSWORD_STARTED = "CHECK_CURRENT_PASSWORD_STARTED";
export const CHECK_CURRENT_PASSWORD_SUCCESS = "CHECK_CURRENT_PASSWORD_SUCCESS";
export const CHECK_CURRENT_PASSWORD_FAILURE = "CHECK_CURRENT_PASSWORD_FAILURE";

export const CHANGE_USER_PASSWORD_STARTED = "CHANGE_USER_PASSWORD_STARTED";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_FAILURE = "CHANGE_USER_PASSWORD_FAILURE";

export const DELETE_USER_ACCOUNT_STARTED = "DELETE_USER_ACCOUNT_STARTED";
export const DELETE_USER_ACCOUNT_SUCCESS = "DELETE_USER_ACCOUNT_SUCCESS";
export const DELETE_USER_ACCOUNT_FAILURE = "DELETE_USER_ACCOUNT_FAILURE";

export const GET_WALLET_DETAILS_STARTED = "GET_WALLET_DETAILS_STARTED";
export const GET_WALLET_DETAILS_SUCCESS = "GET_WALLET_DETAILS_SUCCESS";
export const GET_WALLET_DETAILS_FAILURE = "GET_WALLET_DETAILS_FAILURE";

export const GET_TRANSACTION_EVENTS_STARTED = "GET_TRANSACTION_EVENTS_STARTED";
export const GET_TRANSACTION_EVENTS_SUCCESS = "GET_TRANSACTION_EVENTS_SUCCESS";
export const GET_TRANSACTION_EVENTS_FAILURE = "GET_TRANSACTION_EVENTS_FAILURE";

export const GET_TRANSACTION_RECEIPT_STARTED =
  "GET_TRANSACTION_RECEIPT_STARTED";
export const GET_TRANSACTION_RECEIPT_SUCCESS =
  "GET_TRANSACTION_RECEIPT_SUCCESS";
export const GET_TRANSACTION_RECEIPT_FAILURE =
  "GET_TRANSACTION_RECEIPT_FAILURE";

export const GET_EXCHANGE_RATE_STARTED = "GET_EXCHANGE_RATE_STARTED";
export const GET_EXCHANGE_RATE_SUCCESS = "GET_EXCHANGE_RATE_SUCCESS";
export const GET_EXCHANGE_RATE_FAILURE = "GET_EXCHANGE_RATE_FAILURE";

export const GET_WALLET_TOPUP_RECEIPT_STARTED =
  "GET_WALLET_TOPUP_RECEIPT_STARTED";
export const GET_WALLET_TOPUP_RECEIPT_SUCCESS =
  "GET_WALLET_TOPUP_RECEIPT_SUCCESS";
export const GET_WALLET_TOPUP_RECEIPT_FAILURE =
  "GET_WALLET_TOPUP_RECEIPT_FAILURE";

export const TOPUP_WALLET_BY_INTERNET_PAYMENT_GATEWAY_STARTED =
  "TOPUP_WALLET_BY_INTERNET_PAYMENT_GATEWAY_STARTED";
export const TOPUP_WALLET_BY_INTERNET_PAYMENT_GATEWAY_SUCCESS =
  "TOPUP_WALLET_BY_INTERNET_PAYMENT_GATEWAY_SUCCESS";
export const TOPUP_WALLET_BY_INTERNET_PAYMENT_GATEWAY_FAILURE =
  "TOPUP_WALLET_BY_INTERNET_PAYMENT_GATEWAY_FAILURE";

export const CHANGE_LANGUAGE_STARTED = "CHANGE_LANGUAGE_STARTED";
export const CHANGE_LANGUAGE_SUCCESS = "CHANGE_LANGUAGE_SUCCESS";
export const CHANGE_LANGUAGE_FAILURE = "CHANGE_LANGUAGE_FAILURE";

export const GET_TRANSLATES_STARTED = "GET_TRANSLATES_STARTED";
export const GET_TRANSLATES_SUCCESS = "GET_TRANSLATES_SUCCESS";
export const GET_TRANSLATES_FAILURE = "GET_TRANSLATES_FAILURE";

export const CHANGE_USER_LANGUAGE_STARTED = "CHANGE_USER_LANGUAGE_STARTED";
export const CHANGE_USER_LANGUAGE_SUCCESS = "CHANGE_USER_LANGUAGE_SUCCESS";
export const CHANGE_USER_LANGUAGE_FAILURE = "CHANGE_USER_LANGUAGE_FAILURE";
