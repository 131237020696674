import {
  SIGNIN_DEFAULT,
  GET_WALLETS_STARTED,
  GET_WALLETS_SUCCESS,
  GET_WALLETS_FAILURE,
  GET_INACTIVE_WALLETS_STARTED,
  GET_INACTIVE_WALLETS_SUCCESS,
  GET_INACTIVE_WALLETS_FAILURE,
  ADD_WALLET_STARTED,
  ADD_WALLET_FAILURE,
  ADD_WALLET_SUCCESS,
  GET_TRANSACTION_LIST_STARTED,
  GET_TRANSACTION_LIST_SUCCESS,
  GET_TOP_UP_COUNTRIES_STARTED,
  GET_TOP_UP_COUNTRIES_SUCCESS,
  GET_TOP_UP_COUNTRIES_FAILURE,
  GET_TOP_UP_BANKS_STARTED,
  GET_TOP_UP_BANKS_FAILURE,
  GET_TOP_UP_BANKS_SUCCESS,
  GET_WALLET_TOP_UP_TRANSACTIONS_STARTED,
  GET_WALLET_TOP_UP_TRANSACTIONS_SUCCESS,
  GET_WALLET_TOP_UP_TRANSACTIONS_FAILURE,
  GET_LAST_TRANSACTIONS_STARTED,
  GET_LAST_TRANSACTIONS_SUCCESS,
  GET_TRANSACTION_LIST_FAILURE,
  GET_LAST_EXCHANGES_STARTED,
  GET_LAST_EXCHANGES_SUCCESS,
  GET_LAST_EXCHANGES_FAILURE,
  GET_LAST_TOP_UPS_STARTED,
  GET_LAST_TOP_UPS_FAILURE,
  GET_LAST_TOP_UPS_SUCCESS,
  GET_TRANSACTION_EVENTS_STARTED,
  GET_TRANSACTION_EVENTS_SUCCESS,
  GET_TRANSACTION_EVENTS_FAILURE,
  GET_WALLET_DETAILS_STARTED,
  GET_WALLET_DETAILS_SUCCESS,
  GET_WALLET_DETAILS_FAILURE,
  TOPUP_WALLET_BY_INTERNET_PAYMENT_GATEWAY_STARTED,
  TOPUP_WALLET_BY_INTERNET_PAYMENT_GATEWAY_SUCCESS,
  TOPUP_WALLET_BY_INTERNET_PAYMENT_GATEWAY_FAILURE,
  GET_EXCHANGE_RATE_SUCCESS,
  GET_EXCHANGE_RATE_STARTED,
  GET_EXCHANGE_RATE_FAILURE,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: SIGNIN_DEFAULT,
  wallets: [],
  inactiveWallets: [],
  transactionsList: [],
  topupCountries: [],
  topupBanks: [],
  walletTopUpTransactions: [],
  lastTransactions: [],
  lastExchanges: [],
  lastTopUps: [],
  transactionEvents: [],
  error: "",
};
const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WALLETS_STARTED:
    case GET_INACTIVE_WALLETS_STARTED:
    case ADD_WALLET_STARTED:
    case GET_TRANSACTION_LIST_STARTED:
    case GET_WALLET_DETAILS_STARTED:
    case GET_LAST_TRANSACTIONS_STARTED:
    case GET_LAST_EXCHANGES_STARTED:
    case GET_LAST_TOP_UPS_STARTED:
    case GET_TOP_UP_COUNTRIES_STARTED:
    case GET_TOP_UP_BANKS_STARTED:
    case GET_WALLET_TOP_UP_TRANSACTIONS_STARTED:
    case GET_TRANSACTION_EVENTS_STARTED:
    case GET_EXCHANGE_RATE_STARTED:
    case TOPUP_WALLET_BY_INTERNET_PAYMENT_GATEWAY_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_WALLET_DETAILS_SUCCESS:
    case ADD_WALLET_SUCCESS:
    case GET_EXCHANGE_RATE_SUCCESS:
    case TOPUP_WALLET_BY_INTERNET_PAYMENT_GATEWAY_SUCCESS:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case GET_WALLETS_SUCCESS:
      return {
        ...state,
        rType: GET_WALLETS_SUCCESS,
        wallets: action.payload.wallets,
        error: "",
      };
    case GET_INACTIVE_WALLETS_SUCCESS:
      return {
        ...state,
        rType: GET_INACTIVE_WALLETS_SUCCESS,
        inactiveWallets: action.payload.inactiveWallets,
        error: "",
      };
    case GET_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        rType: GET_TRANSACTION_LIST_SUCCESS,
        transactionsList: action.payload.transactionsList,
        error: "",
      };
    case GET_LAST_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        rType: GET_LAST_TRANSACTIONS_SUCCESS,
        lastTransactions: action.payload.lastTransactions,
        error: "",
      };
    case GET_LAST_EXCHANGES_SUCCESS:
      return {
        ...state,
        rType: GET_LAST_EXCHANGES_SUCCESS,
        lastExchanges: action.payload.lastExchanges,
        error: "",
      };
    case GET_LAST_TOP_UPS_SUCCESS:
      return {
        ...state,
        rType: GET_LAST_TOP_UPS_SUCCESS,
        lastTopUps: action.payload.lastTopUps,
        error: "",
      };
    case GET_TOP_UP_COUNTRIES_SUCCESS:
      return {
        ...state,
        rType: GET_TOP_UP_COUNTRIES_SUCCESS,
        topupCountries: action.payload.topupCountries,
        error: "",
      };
    case GET_TOP_UP_BANKS_SUCCESS:
      return {
        ...state,
        rType: GET_TOP_UP_BANKS_SUCCESS,
        topupBanks: action.payload.topupBanks,
        error: "",
      };
    case GET_WALLET_TOP_UP_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        rType: GET_WALLET_TOP_UP_TRANSACTIONS_SUCCESS,
        walletTopUpTransactions: action.payload.walletTopUpTransactions,
        error: "",
      };
    case GET_TRANSACTION_EVENTS_SUCCESS:
      return {
        ...state,
        rType: GET_TRANSACTION_EVENTS_SUCCESS,
        transactionEvents: action.payload.transactionEvents,
        error: "",
      };
    case GET_WALLETS_FAILURE:
    case GET_INACTIVE_WALLETS_FAILURE:
    case ADD_WALLET_FAILURE:
    case GET_TRANSACTION_LIST_FAILURE:
    case GET_LAST_EXCHANGES_FAILURE:
    case GET_LAST_TOP_UPS_FAILURE:
    case GET_TOP_UP_COUNTRIES_FAILURE:
    case GET_TOP_UP_BANKS_FAILURE:
    case GET_WALLET_TOP_UP_TRANSACTIONS_FAILURE:
    case GET_TRANSACTION_EVENTS_FAILURE:
    case GET_WALLET_DETAILS_FAILURE:
    case GET_EXCHANGE_RATE_FAILURE:
    case TOPUP_WALLET_BY_INTERNET_PAYMENT_GATEWAY_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default walletReducer;
