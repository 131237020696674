import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import authReducer from "./reducers/authReducer";
import walletReducer from "./reducers/walletReducer";
import { CardReducer } from "./reducers/CardReducer";
import GeneralReducer from "./reducers/GeneralReducer";
import todoReducers from "./reducers/Reducers";
//import { reducer as reduxFormReducer } from 'redux-form';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { createWhitelistFilter } from "redux-persist-transform-filter";
import { SettingsReducer } from "./reducers/settingsReducer";
// --------------------------------------------------------------------------------
const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  posts: PostsReducer,
  auth: authReducer,
  card: CardReducer,
  wallet: walletReducer,
  settings: SettingsReducer,
  general: GeneralReducer,
  //form: reduxFormReducer,
});

const persistConfig = {
  timeout: 0,
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  whitelist: ["auth", "settings"],
  transforms: [
    createWhitelistFilter("auth", ["token"]),
    createWhitelistFilter("settings", ["lang"]),
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer,
  composeEnhancers(middleware)
);

export const persistor = persistStore(store);
