import {
  SIGNIN_DEFAULT,
  GET_EVENTS_STARTED,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: SIGNIN_DEFAULT,
  events: [],
  error: "",
};
const GeneralReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENTS_STARTED:
      return {
        ...state,
        rType: GET_EVENTS_STARTED,
        error: "",
      };
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        rType: GET_EVENTS_SUCCESS,
        events: action.payload.events,
        error: "",
      };
    case GET_EVENTS_FAILURE:
      return {
        ...state,
        rType: GET_EVENTS_FAILURE,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default GeneralReducer;
