import {
  CHANGE_KYC_STATUS_FAILURE,
  CHANGE_KYC_STATUS_STARTED,
  CHANGE_KYC_STATUS_SUCCESS,
  CREATE_PERSONAL_INFORMATION_FAILURE,
  CREATE_PERSONAL_INFORMATION_STARTED,
  CREATE_PERSONAL_INFORMATION_SUCCESS,
  FORGOT_PASSWORD_STEP_ONE_FAILURE,
  FORGOT_PASSWORD_STEP_ONE_STARTED,
  FORGOT_PASSWORD_STEP_ONE_SUCCESS,
  FORGOT_PASSWORD_STEP_THREE_FAILURE,
  FORGOT_PASSWORD_STEP_THREE_STARTED,
  FORGOT_PASSWORD_STEP_THREE_SUCCESS,
  FORGOT_PASSWORD_STEP_TWO_FAILURE,
  FORGOT_PASSWORD_STEP_TWO_STARTED,
  FORGOT_PASSWORD_STEP_TWO_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_PROFILE_STARTED,
  GET_PROFILE_SUCCESS,
  SIGNIN_DEFAULT,
  SIGNIN_FAILURE,
  SIGNIN_STARTED,
  SIGNIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_STARTED,
  LOGOUT_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_STARTED,
  SIGNUP_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  VERIFY_EMAIL_STARTED,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_TOKEN_FAILURE,
  VERIFY_EMAIL_TOKEN_STARTED,
  VERIFY_EMAIL_TOKEN_SUCCESS,
  VERIFY_FAILURE,
  VERIFY_MOBILE_FAILURE,
  VERIFY_MOBILE_STARTED,
  VERIFY_MOBILE_SUCCESS,
  VERIFY_MOBILE_TOKEN_FAILURE,
  VERIFY_MOBILE_TOKEN_STARTED,
  VERIFY_MOBILE_TOKEN_SUCCESS,
  VERIFY_STARTED,
  VERIFY_SUCCESS,
  CHECK_EMAIL_STARTED,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_FAILURE,
  UPLOAD_FILE_STARTED,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  CREATE_NATIONALITY_INFORMATION_STARTED,
  CREATE_NATIONALITY_INFORMATION_SUCCESS,
  CREATE_NATIONALITY_INFORMATION_FAILURE,
  CREATE_ADDRESS_INFORMATION_STARTED,
  CREATE_ADDRESS_INFORMATION_SUCCESS,
  CREATE_ADDRESS_INFORMATION_FAILURE,
  GET_COUNTRIES_STARTED,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  DELETE_DOCUMENT_SUCCESS,
  GET_CITIES_STARTED,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
  GET_STATES_STARTED,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
  RESET_ERROR_SUCCESS,
  CREATE_DOCUMENT_INFORMATION_STARTED,
  CREATE_DOCUMENT_INFORMATION_SUCCESS,
  CREATE_DOCUMENT_INFORMATION_FAILURE,
  GET_OCCUPATIONS_STARTED,
  GET_OCCUPATIONS_SUCCESS,
  GET_OCCUPATIONS_FAILURE,
  DOWNLOAD_AGREEMENT_STARTED,
  DOWNLOAD_AGREEMENT_SUCCESS,
  DOWNLOAD_AGREEMENT_FAILURE,
  GET_KNOW_YOUR_CUSTOMER_SUCCESS,
  GET_KNOW_YOUR_CUSTOMER_STARTED,
  GET_KNOW_YOUR_CUSTOMER_FAILURE,
  VERIFY_KYC_REVIEW_STARTED,
  VERIFY_KYC_REVIEW_SUCCESS,
  VERIFY_KYC_REVIEW_FAILURE,
  GET_CAPTCHA_SUCCESS,
  GET_CAPTCHA_FAILURE,
  GET_CAPTCHA_STARTED,
  GET_AGREEMENTS_SUCCESS,
  GET_AGREEMENTS_STARTED,
  GET_AGREEMENTS_FAILURE,
  GET_USER_SETTING_STARTED,
  GET_USER_SETTING_FAILURE,
  GET_USER_SETTING_SUCCESS,
  VERIFY_OTP_STARTED,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  SEND_OTP_STARTED,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILURE,
  GET_NATIONALITIES_STARTED,
  GET_NATIONALITIES_SUCCESS,
  GET_NATIONALITIES_FAILURE,
  DELETE_USER_ACCOUNT_STARTED,
  DELETE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT_FAILURE,
  CHECK_CURRENT_PASSWORD_STARTED,
  CHECK_CURRENT_PASSWORD_SUCCESS,
  CHECK_CURRENT_PASSWORD_FAILURE,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: SIGNIN_DEFAULT,
  token: "",
  otp: false,
  user: {},
  isStarterUser: true,
  kyc: [],
  nationalities: [],
  countries: [],
  states: [],
  cities: [],
  occupations: [],
  error: "",
  captcha: {},
  agreements: [],
  userSetting: {},
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_STARTED:
    case SIGNIN_STARTED:
    case VERIFY_STARTED:
    case LOGOUT_STARTED:
    case FORGOT_PASSWORD_STEP_ONE_STARTED:
    case FORGOT_PASSWORD_STEP_TWO_STARTED:
    case FORGOT_PASSWORD_STEP_THREE_STARTED:
    case CHECK_EMAIL_STARTED:
    case GET_PROFILE_STARTED:
    case VERIFY_EMAIL_STARTED:
    case VERIFY_EMAIL_TOKEN_STARTED:
    case VERIFY_MOBILE_STARTED:
    case VERIFY_MOBILE_TOKEN_STARTED:
    case CREATE_PERSONAL_INFORMATION_STARTED:
    case CREATE_NATIONALITY_INFORMATION_STARTED:
    case CREATE_ADDRESS_INFORMATION_STARTED:
    case CREATE_DOCUMENT_INFORMATION_STARTED:
    case CHANGE_KYC_STATUS_STARTED:
    case UPLOAD_FILE_STARTED:
    case GET_NATIONALITIES_STARTED:
    case GET_COUNTRIES_STARTED:
    case GET_STATES_STARTED:
    case GET_CITIES_STARTED:
    case GET_OCCUPATIONS_STARTED:
    case VERIFY_KYC_REVIEW_STARTED:
    case DOWNLOAD_AGREEMENT_STARTED:
    case GET_KNOW_YOUR_CUSTOMER_STARTED:
    case GET_CAPTCHA_STARTED:
    case GET_AGREEMENTS_STARTED:
    case GET_USER_SETTING_STARTED:
    case SEND_OTP_STARTED:
    case VERIFY_OTP_STARTED:
    case DELETE_USER_ACCOUNT_STARTED:
    case CHECK_CURRENT_PASSWORD_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case SIGNUP_SUCCESS:
    case FORGOT_PASSWORD_STEP_ONE_SUCCESS:
    case FORGOT_PASSWORD_STEP_TWO_SUCCESS:
    case FORGOT_PASSWORD_STEP_THREE_SUCCESS:
    case CHECK_EMAIL_SUCCESS:
    case CREATE_PERSONAL_INFORMATION_SUCCESS:
    case CREATE_NATIONALITY_INFORMATION_SUCCESS:
    case CREATE_ADDRESS_INFORMATION_SUCCESS:
    case CREATE_DOCUMENT_INFORMATION_SUCCESS:
    case VERIFY_MOBILE_TOKEN_SUCCESS:
    case VERIFY_EMAIL_SUCCESS:
    case UPLOAD_FILE_SUCCESS:
    case GET_OCCUPATIONS_SUCCESS:
    case VERIFY_KYC_REVIEW_SUCCESS:
    case DOWNLOAD_AGREEMENT_SUCCESS:
    case SEND_OTP_SUCCESS:
    case VERIFY_OTP_SUCCESS:
    case DELETE_USER_ACCOUNT_SUCCESS:
    case CHECK_CURRENT_PASSWORD_SUCCESS:
      return {
        ...state,
        rType: action.type,
        ...action.payload,
        error: "",
      };
    case SIGNIN_SUCCESS:
      return {
        ...state,
        rType: SIGNIN_SUCCESS,
        token: action.payload.token,
        user: action.payload.user,
        isStarterUser: action.payload.isStarterUser,
        error: "",
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        rType: SIGNIN_SUCCESS,
        token: action.payload.token,
        user: action.payload.user,
        isStarterUser: action.payload.isStarterUser,
        otp: false,
        error: "",
      };
    case LOGOUT_SUCCESS:
      return initialState;
    case VERIFY_EMAIL_TOKEN_SUCCESS:
      return {
        ...state,
        rType: VERIFY_EMAIL_TOKEN_SUCCESS,
        user: {
          ...state.user,
          email_verified_at: action.payload.email_verified_at,
        },
        error: "",
      };
    case VERIFY_MOBILE_SUCCESS:
      return {
        ...state,
        rType: VERIFY_MOBILE_SUCCESS,
        user: {
          ...state.user,
          mobile: action.payload.mobile,
          country_code: action.payload.countryCode,
        },
        error: "",
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        rType: GET_PROFILE_SUCCESS,
        user: action.payload.user,
        isStarterUser: action.payload.isStarterUser,
        error: "",
      };
    case CHANGE_KYC_STATUS_SUCCESS:
      return {
        ...state,
        rType: GET_PROFILE_SUCCESS,
        user: { ...state.user, KYC_status: action.payload.KYC_status },
        error: "",
      };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        rType: GET_PROFILE_SUCCESS,
        user: { ...state.user, ...action.payload },
        error: "",
      };
    case GET_NATIONALITIES_SUCCESS:
      return {
        ...state,
        rType: GET_NATIONALITIES_SUCCESS,
        nationalities: action.payload.nationalities,
        error: "",
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        rType: GET_COUNTRIES_SUCCESS,
        countries: action.payload.countries,
        // states: [],
        // cities: [],
        error: "",
      };
    case GET_STATES_SUCCESS:
      return {
        ...state,
        rType: GET_STATES_SUCCESS,
        states: action.payload.states,
        // cities: [],
        error: "",
      };
    case GET_CITIES_SUCCESS:
      return {
        ...state,
        rType: GET_CITIES_SUCCESS,
        cities: action.payload.cities,
        error: "",
      };
    case GET_KNOW_YOUR_CUSTOMER_SUCCESS:
      return {
        ...state,
        rType: GET_KNOW_YOUR_CUSTOMER_SUCCESS,
        kyc: action.payload.kyc,
        error: "",
      };
    case GET_CAPTCHA_SUCCESS:
      return {
        ...state,
        rType: GET_CAPTCHA_SUCCESS,
        captcha: action.payload.captcha,
        error: "",
      };
    case GET_AGREEMENTS_SUCCESS:
      return {
        ...state,
        rType: GET_AGREEMENTS_SUCCESS,
        agreements: action.payload.agreements,
        error: "",
      };
    case RESET_ERROR_SUCCESS:
      return {
        ...state,
        rType: RESET_ERROR_SUCCESS,
        error: "",
      };
    case GET_USER_SETTING_SUCCESS:
      return {
        ...state,
        rType: GET_USER_SETTING_SUCCESS,
        userSetting: action.payload.userSetting,
        error: "",
      };
    case SIGNUP_FAILURE:
    case SIGNIN_FAILURE:
    case LOGOUT_FAILURE:
    case VERIFY_FAILURE:
    case FORGOT_PASSWORD_STEP_ONE_FAILURE:
    case FORGOT_PASSWORD_STEP_TWO_FAILURE:
    case FORGOT_PASSWORD_STEP_THREE_FAILURE:
    case CHECK_EMAIL_FAILURE:
    case CREATE_PERSONAL_INFORMATION_FAILURE:
    case CREATE_NATIONALITY_INFORMATION_FAILURE:
    case CREATE_ADDRESS_INFORMATION_FAILURE:
    case CREATE_DOCUMENT_INFORMATION_FAILURE:
    case GET_PROFILE_FAILURE:
    case VERIFY_EMAIL_FAILURE:
    case VERIFY_EMAIL_TOKEN_FAILURE:
    case VERIFY_MOBILE_FAILURE:
    case VERIFY_MOBILE_TOKEN_FAILURE:
    case CHANGE_KYC_STATUS_FAILURE:
    case UPLOAD_FILE_FAILURE:
    case GET_NATIONALITIES_FAILURE:
    case GET_COUNTRIES_FAILURE:
    case GET_STATES_FAILURE:
    case GET_CITIES_FAILURE:
    case GET_OCCUPATIONS_FAILURE:
    case VERIFY_KYC_REVIEW_FAILURE:
    case DOWNLOAD_AGREEMENT_FAILURE:
    case GET_KNOW_YOUR_CUSTOMER_FAILURE:
    case GET_CAPTCHA_FAILURE:
    case GET_AGREEMENTS_FAILURE:
    case GET_USER_SETTING_FAILURE:
    case SEND_OTP_FAILURE:
    case VERIFY_OTP_FAILURE:
    case DELETE_USER_ACCOUNT_FAILURE:
    case CHECK_CURRENT_PASSWORD_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
