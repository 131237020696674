import {
  ASSIGN_CARD_DEFAULT,
  GET_TIME_ZONES_STARTED,
  GET_TIME_ZONES_SUCCESS,
  GET_TIME_ZONES_FAILURE,
  CHANGE_SETTING_STARTED,
  CHANGE_SETTING_SUCCESS,
  CHANGE_SETTING_FAILURE,
  CHANGE_USER_PASSWORD_STARTED,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_FAILURE,
  CHANGE_LANGUAGE_SUCCESS,
  GET_TRANSLATES_SUCCESS,
  GET_TRANSLATES_STARTED,
  GET_TRANSLATES_FAILURE,
  CHANGE_USER_LANGUAGE_STARTED,
  CHANGE_USER_LANGUAGE_SUCCESS,
  CHANGE_USER_LANGUAGE_FAILURE,
} from "../actionTypes";
// --------------------------------------------------------------------------------
const initialState = {
  rType: null,
  timeZones: [],
  lang: "en",
  translates: [],
  error: "",
};
export const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TIME_ZONES_STARTED:
    case CHANGE_SETTING_STARTED:
    case CHANGE_USER_PASSWORD_STARTED:
    case GET_TRANSLATES_STARTED:
    case CHANGE_USER_LANGUAGE_STARTED:
      return {
        ...state,
        rType: action.type,
        error: "",
      };
    case CHANGE_SETTING_SUCCESS:
    case CHANGE_USER_PASSWORD_SUCCESS:
    case CHANGE_USER_LANGUAGE_SUCCESS:
      return {
        ...state,
        rType: action.rType,
        error: "",
      };
    case GET_TIME_ZONES_SUCCESS:
      return {
        ...state,
        rType: action.rType,
        timeZones: action.payload.timeZones,
        error: "",
      };
    case CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        rType: action.rType,
        lang: action.payload.lang,
        error: "",
      };
    case GET_TRANSLATES_SUCCESS:
      return {
        ...state,
        rType: GET_TRANSLATES_SUCCESS,
        translates: action.payload.translates,
        error: "",
      };
    case GET_TIME_ZONES_FAILURE:
    case CHANGE_SETTING_FAILURE:
    case CHANGE_USER_PASSWORD_FAILURE:
    case GET_TRANSLATES_FAILURE:
    case CHANGE_USER_LANGUAGE_FAILURE:
      return {
        ...state,
        rType: action.type,
        error: action.payload,
      };
    default:
      return state;
  }
};
